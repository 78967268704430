.js .cookie-consent {
  display: none; /* Displayed via JavaScript. */
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  transition: background-color 250ms linear;

  &-modal {
    display: block;
    max-width: 500px;
    max-height: 85vh;
    overflow: auto;
    width: 100%;
    z-index: 1000000;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 15px;
    line-height: 1.5;
  }

  &--hidden {
    display: none;
  }

  &--open {
    display: flex;
  }

  .h3 {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 5px;
    text-align: center;
    font-weight: 400;
  }

  .h4 {
    margin-top: .75em;
    font-size: 1.2rem;
    font-weight: 400;
  }

  .h5 {
    margin-top: .5em;
    font-size: 1rem;
    font-weight: 400;
  }

  p {
    text-align: center;
    font-size: .9rem;
    font-weight: 300;
    margin-bottom: 7px;
  }

  .text-gray-600 {
    color: #718096;
  }

  .flex {
    display: flex;
  }

  .flex-auto {
    flex: 1 1 auto;
  }

  .button {
    width: 100%;
    cursor: pointer;
    text-align: center;
    margin-bottom: 10px;

    &:hover {
      background: #c62017;
    }

    /*
    &-optout {
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      padding: 10px;
      margin: 10px 0;
      background: lighten(#dcdeec, 5%);
      color: #333;
      transition: background .5s;

      &:hover {
        background: #dcdeec;
      }
    }
    */
  }

  dl {
    font-size: .8rem;
    margin: 3px 0 0 0;
    overflow: auto;
    opacity: .65;
    transition: opacity .35s;
    background: #f1f1f1;
    padding: 4px 7px;

    &:hover {
      opacity: 1;
    }

    dt {
      float: left;
      width: 30%;

      &:after {
        content: ': ';
      }
    }

    dd {
      float: left;
      width: 70%;
      margin-left: 0;

      a {
        font-size: .8rem;
      }
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    font-size: 14px;
    line-height: 1.5;
    margin-top: 15px;
    margin-bottom: 0;

    li {
      border-left: 1px solid #9caccf;
      padding: 0 5px;

      &.first {
        border-left: none;
      }

      .link {
        cursor: pointer;
        line-height: 1.3;

        &.toggle-preferences {
          position: relative;
          padding-left: 15px;

          &:before {
            content: '›';
            position: absolute;
            top: 1px;
            left: 7px;
            font-size: 17px;
            line-height: 1;
            transform: rotate(90deg);
          }

          &--open {
            &:before {
              transform: rotate(-90deg);
              left: 5px;
            }
          }
        }

        &:hover {
          outline-width: 0;
        }
      }

      a {
        border-bottom: none;

        &:hover {
          outline-width: 0;
        }
      }
    }
  }

  .toggle-switch {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 50px;
    height: 18px;
    display: inline-block;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: #4a5568;
    transition: background-color ease 0.3s;
    float: right;
    margin: 17px 0 0 0;

    &:before {
      content: 'an aus';
      display: block;
      position: absolute;
      z-index: 2;
      width: 14px;
      height: 14px;
      background: #fff;
      left: 2px;
      top: 2px;
      border-radius: 2px;
      line-height: 1.3;
      font-size: 12px;
      text-transform: uppercase;
      text-indent: -25px;
      word-spacing: 24px;
      color: #fff;
      white-space: nowrap;
      transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
    }

    &:disabled {
      cursor: default;
      opacity: .5;
    }

    &--active,
    &:checked {
      background-color: #48bb78;
    }

    &--active:before,
    &:checked:before {
      left: 34px;
    }
  }

  .toggle-description {
    font-size: 14px;
    float: right;
    margin: 15px 10px 0 0;
  }

  /* Step 1. */
  &-step1 {
    float: left;

    @media (min-width: 640px) {
      margin: 10px;
    }

    &--hidden {
      display: none;
    }
  }

  /* Step 2. */
  &-step2 {
    border-top: 1px solid #edeef5;
    margin: 0 10px 5px 10px;
    float: left;

    &--hidden {
      display: none;
    }

    p {
      text-align: left;
      margin: 0;
    }

    .cookie-consent-footer {
      margin-top: 15px;
    }
  }

  &-footer {
    width: 100%;
    float: left;
  }
}
