@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import "_fonts.css";
@import "_icons.css";
@import "_sanitize.css";
@import "_grid.css";
@import "_consent.css";
@import "_old.css";
@import "_timeline.css";

html {
  @apply scroll-smooth;
}

body {
  @apply bg-white;
}

.gradient-overlay {
  @apply relative;

  &:after {
    @apply bg-gradient-to-br from-primary-500 to-primary-700 mix-blend-screen backdrop-opacity-75;

    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;
  }
}

.text-shadow {
  text-shadow: 0 0 3px #9f1c15;
}

input:checked + svg {
  @apply block;
}

.meta-content {
  h1 {
    @apply text-5xl mt-5 mb-3 font-medium;
  }

  h2 {
    @apply mt-5 mb-3 font-medium;
  }

  h3, h4 {
    @apply mb-3 font-medium;
  }

  p {
    @apply mb-3 font-light;
  }

  ul, ol {
    @apply mb-3 font-light;
  }
}
