/* Global site settings. */
.headline {
  position: absolute;
  text-transform: uppercase;
  color: #fff;

  .first {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    transform: translate(0, 0);
    @apply text-gray-300/50;
  }

  .second {
    position: absolute;
    top: .45em;
    left: 0;
    z-index: 10;
    transform: translate(-15px, 0);
    transition: transform .5s;
  }

  .primary {
    color: #e6332a;
  }
}

.page {

  h2 {
    font-size: 26px;
  }

  ul {
    list-style: disc;
    padding-left: 25px;
  }

}

.js-inview .headline {
  .second {
    transform: translate(50px, 0);
  }
}

.has-fade {
  opacity: 1;
  transition: opacity .3s;
}

.is-hidden {
  opacity: 0;
}

/* Helpers */
.center {
  text-align: center;
}

.hide-on-mobile,
.hide-on-tablet {
  display: none;
}

.break-on-mobile {
  display: block;
}

/* Elements */
.button {
  padding: 10px 15px 8px 15px;
  color: #e6332a;
  background: #fff;
  border-bottom: 2px solid #fff;
  transition: background .3s;

  &.button-outline {
    color: #e6332a;
    border: 2px solid #e6332a;
    font-weight: 600;
    width: 100%;
    transition: border .3s;
      text-align: center;

    &:hover {
      border: 2px solid #841d18;
    }
  }


  &.button-primary {
    color: #fff;
    background: #e6332a;
    border-bottom: 2px solid #e6332a;

    &:hover {
      background: transparentize(#e6332a, .3);
    }
  }

  &:hover {
    background: transparentize(#fff, .3);
    text-decoration: none;
  }

  &.button-submit {
    text-transform: uppercase;

    &:disabled,
    &[disabled] {
      opacity: .6;
    }
  }
}

/* Menu and navigation */
.open-menu {
  display: none;
}

.js .open-menu {
  display: block;
  cursor: pointer;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  margin: 0;
  padding: 7px;
  font-size: 20px;

  &:hover {
    background: #841d18;
  }
}

.nav-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: transparentize(#333, .8);
  opacity: 0;
  transition: opacity .5s;
  pointer-events: none; /* Make sure links are not clickable. */

  &.is-open {
    pointer-events: initial; /* Make sure links are clickable. */
    opacity: 1;
  }

  ol {
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }

  li {
    width: 100%;
    float: left;
    position: relative;
    margin: 10px 0;

    &:before {
      content: '';
      position: absolute;
      top: 32px;
      left: 75px;
      width: 30px;
      height: 2px;
      background: #fff;
      box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.5);
      transition: background .3s;
    }

    &:hover {
      &:before {
        background: #333;
      }
    }
  }

  a {
    width: 100%;
    float: left;
    color: #fff;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    font-size: 20px;
    text-transform: uppercase;
    padding: 20px 0 20px 120px;
    transition: color .3s;

    &:hover,
    &:active {
      color: #333;
      text-decoration: none;
    }
  }

  .first-item-link,
  .second-item-link,
  .third-item-link,
  .fourth-item-link {

    &:before {
      @apply font-numbers;

      position: absolute;
      top: -5px;
      left: 28px;
      text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
      font-size: 55px;
      font-weight: 400;
    }

    &:after {
      @apply font-numbers;

      content: '0';
      position: absolute;
      top: 7px;
      left: 0;
      text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
      font-size: 43px;
      font-weight: 400;
    }
  }

  .menu-item {
    opacity: 0;
    transition: opacity .5s;
  }

  .is-active .second-item {
    transition-delay: .25s;
  }

  .is-active .third-item {
    transition-delay: .5s;
  }

  .is-active .fourth-item {
    transition-delay: .75s;
  }

  .is-active .menu-item {
    opacity: 1;
  }

  .first-item-link {
    &:before {
      content: '1';
    }
  }

  .second-item-link {
    &:before {
      content: '2';
    }
  }

  .third-item-link {
    &:before {
      content: '3';
    }
  }

  .fourth-item-link {
    &:before {
      content: '4';
    }
  }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {

  .page .site-header .inner h2,
  .page .site-header .site-logo {
    display: none;
  }

  .page .site-header .inner {
    height: 35vh;
  }

  .page .site-login {
    transform: translateY(0);
  }

  .nav-main {
    ol {
      li {
        margin-bottom: 0;
      }
    }
  }

}

/* Sections */
.site-header {
  position: relative;
  overflow: hidden;
  color: #fff;
  background: #333 url("/media/bg/mobile/header-bg.png") center left no-repeat;
  background-size: cover;
  padding: 25px;
  height: 100vh;

  &.site-header-full {
    height: 100vh;

    .inner {
      transform: translateY(0);
    }

    .logo {
      margin: 0 auto;
    }
  }

  h2 {
    position: absolute;
    bottom: 25px;
    left: 0;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  }

  .logo {
    width: 70%;
    margin: 10% auto;
  }
}

.frontpage .site-header {
  max-height: 650px;
}

.content {
  max-width: 100%;
  margin: 0 auto;

  &.has-max-width {
    max-width: 1400px;
  }
}

.section {
  box-sizing: border-box;
  width: 100%;
  float: left;
  position: relative;

  .col {
    padding: 25px;
  }
}

.section-about {
  .aside-about {
    .aside-info {
      &:before {
        content: '01';
      }
    }
  }

}

.js .section-about {
  .list-skills {
    .js-fadein .asset {
      opacity: 1;
    }
  }
}

.section-work {
  /* max-height: 650px; */

  .col-right {
    background: transparent url("/media/bg/mobile/augsburg-skyline-bg.png") center left no-repeat;
    background-size: cover;
    height: 100vh;
    max-height: 650px;
    padding: 0;

    .inner {
      color: #fff;
      background: transparentize(#e6332a, .3);
      height: 100vh;
      max-height: 650px;
      padding: 25px;
    }

    .headline {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      .first {
        @apply text-gray-300/50;
      }

    }

  }

  .col-left {
    height: 100vh;
    max-height: 650px;

    p {
      padding-left: 53px;
      padding-right: 25px;
    }

    img {
      padding-top: 25px;
    }

    .inner {
      transform: translateY(25%)
    }

  }

  .aside-work {
    color: #fff;

    .aside-info {
      &:before {
        content: '02';
      }

      &:after {
        background: #fff
      }
    }

  }

}

.section-about {
  .has-bg-image {
    @apply isolate;

    background: url("/media/bg/profilbild-christoph-baur-2021.png") top center no-repeat;
    background-size: cover;
  }
}

.section-work {
  .has-bg-image {
    @apply isolate;

    background: url("/media/bg/augsburg-skyline.png") center center no-repeat;
    background-size: cover;
  }
}

.section-lehrauftrag {
  .has-bg-image {
    @apply isolate;

    background: url("/media/bg/vorlesung.png") center center no-repeat;
    background-size: cover;
  }

  .col-left {
    color: #fff;
    background: #e6332a;
  }

  h3 {
    margin-top: 0;
  }

  .list-downloads {
    width: 100%;
    float: left;

    li {
      float: left;
      margin: 15px 0;

      &:last-child {
        margin-right: 0;
      }

      .button {
        float: left;
      }

    }

  }

  .aside-lehrauftrag {

    .aside-info {
      top: 41%;

      &:before {
        content: '02';
      }
    }

  }

}

.section-awards {

  .col-left {
    color: #fff;
    background: transparentize(#e6332a, .3) url("/media/bg/mobile/semy-bg.png") center center no-repeat;
    background-size: cover;
    height: 100vh;
    max-height: 650px;
    padding: 0;

    .inner {
      padding: 25px;
      /* transform: translateY(25%) */
    }

    .headline .first {
      @apply text-gray-300/50;
    }

    p {
      padding-left: 30px;
    }

  }

  .col-right {

    .inner {
      padding: 25px 30px;
    }

    h3 {
      margin-top: 0;
    }

    .list-awards {
      padding: 0 0 10px 0;

      li {
        margin: 20px 0;
      }
    }

  }

  .aside-awards {

    .aside-info {
      &:before {
        content: '02';
      }
    }

  }

}

.section-referenzen {
  .col-wide {
    color: #fff;
    background: #333 url("/media/bg/mobile/studenten.jpg") center center no-repeat;
    background-size: cover;
    padding: 0;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      pointer-events: none;
      z-index: 1;
    }

    .inner {
      padding: 25px;
    }

    blockquote {
      position: relative;
      z-index: 10;
    }
  }

  .aside-referenzen {
    color: #fff;
    z-index: 10;

    .aside-info {
      &:before {
        content: '03';
      }

      &:after {
        background: #fff;
      }
    }

  }

}

.section-kontakt {

  h4 {
    font-weight: 300;
    margin-top: 15px;
  }

  .vcard a {
    color: #333;
  }

  .list-vcard {
    margin-top: 0;

    li {
      position: relative;
      padding-top: 10px;
    }

  }

  .company-name {
    padding-top: 0;

    h3 {
      margin-top: 0;
    }

  }

  .company-name,
  .company-tel {
    padding-bottom: 15px;
    margin-bottom: 5px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50%;
      height: 1px;
      background: transparentize(#333, .3);
    }

  }

  .list-nav-meta {

    li {
      padding-top: 5px;

      a {
        @apply decoration-1 underline-offset-2;

        &:hover {
          @apply underline;
        }
      }
    }

  }

  .col-contactform,
  .col-social {
    color: #fff;
    background: #e6332a;
  }

  .col-contactform {

    p {
      padding: 2px; /* Fix for :focus input state. */
    }

    input[type=checkbox]:focus,
    input[type=password]:focus,
    input[type=text]:focus,
    input[type=email]:focus,
    textarea:focus {
      box-shadow: 0 0 2px #fff;
    }
  }


  .list-networks {

    li {
      float: left;
      text-align: center;
      font-size: 55px;
      padding: 0;

      &.first {
        text-align: left;
      }

      &.last {
        text-align: right;
      }
    }

    a {
      color: #fff;
      transition: color .3s;

      &:hover {
        color: #333;
        text-decoration: none;
      }
    }

  }

  .form-contact {
    width: 100%;
    float: left;
    position: relative;
  }

  .form-group--input,
  .form-group--submit {
    width: 100%;
    float: left;
    padding: 18px 2px 2px 2px;
  }

  .form-group--accept .response {
    margin-left: 22px;
    padding-left: 7px;
    width: 90%;
  }

  .control-label,
  .form-control {
    width: 100%;
    float: left;
    padding: 7px;
  }

  .control-label:after {
    content: '*';
    margin-left: 3px;
  }

  .response {
    float: left;
    width: 100%;
    padding: 3px 7px;
    margin-top: 3px;
    font-weight: 300;
    font-size: 75%;

    &.response-error {
      background: #e6332a;
      color: white;
    }

    &.response-spam {
      color: #e6332a;
      padding-top: 15px;
      padding-left: 0;
      font-size: 100%;
    }

    &.response-success {
      color: #00435f;
      padding-top: 15px;
      padding-left: 0;
      font-size: 100%;
    }

  }

  .aside-kontakt {

    .aside-info {
      &:before {
        content: '04';
      }
    }

  }

  .checkbox input[type="checkbox"] {
    opacity: 0;
  }

  .checkbox label {
    position: relative;
    display: inline-block;
    padding-left: 22px;
  }

  .checkbox label::before,
  .checkbox label::after {
    position: absolute;
    content: "";
    display: inline-block;
  }

  .checkbox label::before {
    height: 16px;
    width: 16px;
    border: 1px solid #fff;
    left: 0;
    top: 3px;
    cursor: pointer;
  }

  .checkbox label::after {
    height: 5px;
    width: 9px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(-45deg);
    left: 4px;
    top: 7px;
  }

  .checkbox a {
    color: #fff;
    text-decoration: underline;
  }

  .checkbox input[type="checkbox"] + label::after {
    content: none;
  }

  .checkbox input[type="checkbox"]:checked + label::after {
    content: "";
  }

  .checkbox input[type="checkbox"]:focus + label::before {
    outline: 0;
    box-shadow: 0 0 2px #e6332a;
  }

}

.aside {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 80px;
  transform: translateX(-15px);

  .aside-info {
    position: relative;
    top: 50%;
    right: 0;
    transform: rotate(90deg);
    text-transform: uppercase;
    font-size: 25px;
    transform-origin: center;

    &:before {
      @apply font-numbers;

      content: ''; /* Current number. */
      position: absolute;
      top: -30px;
      left: -180px;
      font-weight: 400;
      font-size: 75px;
      transform: rotate(-90deg);
      letter-spacing: -0.05em;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: -100px;
      background: #333;
      width: 90px;
      height: 2px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

}

.site-login {
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  background: transparentize(#fff, .6);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .13);
  transform: translateY(30%);

  .control-label {
    color: #333;
    width: 100%;
    float: left;
    font-weight: 400;
    padding: 0 7px 7px 7px;

    &.has-padding--top {
      padding-top: 15px;
    }
  }

  .form-control {
    border: 1px solid #e6332a;
    padding: 9px 7px;
    width: 100%;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .07);
    color: #333;
    background: transparentize(#fff, .6);
  }

  .button.button-submit {
    width: 45%;
    color: #fff;
    background: #e6332a;
    margin-top: 17px;
    border: none;
    transition: background .3s;

    &:hover {
      background: #841d18;
    }
  }

  .response {
    float: left;
    width: 100%;
    padding: 5px 7px;
    font-weight: 300;
    font-size: 75%;
    color: #e6332a;
  }
}

.infobox {
  background: transparentize(#b4b4b4, .8);
  width: 100%;
  float: right;
  padding: 25px;

  h3 {
    margin-top: 15px;
  }
}

.site-footer {
  width: 100%;
  float: left;
  position: relative;
  text-align: center;
  color: #fff;
  background: #e6332a;
}

.page .site-footer {
  background: #fff;;
}

.page-footer {
  padding-top: 50px;
  padding-bottom: 50px;
  color: transparentize(#333, .5)
}

/* Cookie Notice */
.cookie-notice {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #fff;
  background: transparentize(#e6332a, .1);
  padding: 15px;
  z-index: 200;
  font-size: .75em;
  display: none;

  &.cookies-not-accepted {
    display: block;

    &.cookies-accepted {
      display: none;
    }
  }

  p {
    margin: 0 0 10px 0;
    font-size: 1em;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }

  .button.button-primary {
    border: 1px solid #fff;
  }
}

@media (min-width: 667px) {

  /* Helpers */
  .hide-on-mobile {
    display: block;
  }

  .break-on-mobile {
    display: inline;
  }

  .break-on-desktop {
    display: block;
  }

  /* Elements */
  .button {
    padding: 10px 25px 8px 25px;
  }

  /* Sections */
  .nav-main {
    height: 75vh;

    ol {
      width: 35%;
    }
  }

  .site-header {
    height: 75vh;

    .hide-on-mobile {
      display: inline; /* Make sure all span elements are displayed inline. */
    }

    .inner {
      transform: translateY(60%);
      height: 50vh;
      position: absolute;
      width: 100%;

      h1 {
        display: block;
        text-align: center;
        text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);

        .first {
          left: 50%;
          transform: translate(40%, -35px);
          font-size: 75px;
          color: #eeeeee;
        }

        .second {
          font-size: 95px;
        }

      }
    }

    h2 {
      position: relative;
      font-size: 15px;
    }

    .logo {
      position: absolute;
      top: 25px;
      left: 25px;
      width: auto;
      margin: 0;
    }

  }

  .frontpage .site-header {
    .inner {
      max-height: 375px;
    }
  }

  .section-lehrauftrag {

    .col-wide {

      p {
        padding-top: 25px;
      }
    }

  }

  .section-kontakt {

    .form-group--input {
      width: 75%;
    }

    .list-networks li {

      &.first {
        text-align: right;
      }

      &.last {
        text-align: left;
      }
    }

  }

  /* Cookie Notice */
  .cookie-notice {
    padding: 15px 25px;

    p {
      width: 70%;
      float: left;
      margin-bottom: 2px;
    }
    button {
      float: left;
      margin: 15px 0 0 20px;
    }
  }

}

@media (min-width: 768px) {

  .site-header {
    background-image: url("/media/bg/header-bg.png");
  }

  .section-work {

    .col-right {
      background-image: url("/media/bg/augsburg-skyline-bg.png");
    }

    .col-left {
      .inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        p:first-child {
          margin-top: 0; /* Important to center in middle. */
        }
      }
    }

    /*
    .col-left,
    .col-right {
      min-height: 75vh;
    }
    */

  }

  .section-lehrauftrag {

    .col-wide {
      background-image: url("/media/bg/augsburg-bg.png");

      .inner {
        float: left;
        width: 100%;
        position: relative;
        height: 100%;
        transform: translateY(0);
      }

      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

  }

  .section-awards {

    /*
    .col-left,
    .col-right {
      min-height: 75vh;
    }
    */

    .col-left {
      background-image: url("/media/bg/semy-bg.png");

      .inner {
        float: left;
        width: 100%;
        position: relative;
        height: 100%;
        transform: translateY(0);
      }

      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    /*
    .col-right {
      .inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 25px 0;
      }
    }
    */

  }

  .section-referenzen {

    .col-wide {
      background-image: url("/media/bg/studenten.jpg");
    }

  }

  .site-header-full {
    .inner {
      height: 35vh;
    }
  }

  .site-login {
    transform: translate(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
  }

  .infobox {
    width: auto;
    padding: 25px 50px;
  }

}

@media (min-width: 1024px) {

  /* Global */
  h2 {
    font-size: 65px;
  }

  .page h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 24px;
  }

  .hide-on-tablet {
    display: block;
  }

  .js .open-menu {
    top: 25px;
    right: 25px;
    font-size: 30px;
  }

  .nav-main {

    li:before {
      width: 50px;
      height: 3px;
      top: 34px;
      left: 95px;
    }

    a {
      font-size: 24px;
      padding-left: 170px;
    }

    .first-item-link,
    .second-item-link,
    .third-item-link,
    .fourth-item-link {

      &:before {
        top: -10px;
        left: 33px;
        font-size: 65px;
      }

      &:after {
        top: 3px;
        font-size: 54px;
      }
    }

  }

  .js-inview .headline {
    .second {
      transform: translate(50%, 0);
    }
  }

  /* Sections */
  .site-header {

    .logo {
      z-index: 10; /* Place in front of menu overlay. */

      &.is-hidden {
        opacity: 1; /* Do not hide logo when menu is open. */
      }
    }

    .inner {

      h1 {

        .first {
          transform: translate(30%, -55px);
          font-size: 120px;
        }

        .second {
          font-size: 140px;
        }

      }

      h2 {
        font-size: 22px;
      }

    }

  }

  .section-about {
    .list-skills li {
      @apply py-2;
    }
  }

  .section-work {

    .col-right {
      height: 75vh;

      .headline {
        top: 0;
        transform: translateY(0);
      }

      .inner {
        height: 75vh;
      }
    }

    .col-left {
      height: 75vh;

      .inner {
        width: 50%;
        margin: 0 auto;
      }
    }

  }

  .section-lehrauftrag {

    .outer {
      width: 100%;
      float: left;
      color: #fff;
      background: #333 url("/media/bg/augsburg-bg.png") top center no-repeat;
      background-size: cover;
    }

    .col-wide {
      background: transparent;
      height: 50vh;

      p {
        width: 60%;
        margin: 0 auto;
      }
    }

    .col-left {
      background: transparentize(#e6332a, .3);
    }

    .col-right {
      background: transparentize(#fff, .3);
      color: #333;
    }

  }

  /*
  .section-awards {

    .col-left {
      height: 75vh;
    }

  }
  */

  .section-referenzen {

    .list-slider {
      width: 60%;
      margin: 75px auto;
      position: relative;
    }

    blockquote {
      position: relative;
      padding: 50px;

      p {
        font-size: 20px;
      }

      &:before {
        content: '„';
        position: absolute;
        top: -100px; /* Place lower quote at the top. */
        left: 50%;
        transform: translateX(-50%);
        font-size: 160px;
        line-height: 1;
        font-family: Arial, sans-serif;
      }

    }

  }

  .section-kontakt {
    padding-bottom: 25px;

    .col-1-1 .headline {
      padding-bottom: 50px;
    }

    .col-contact,
    .col-contactform,
    .col-social {
      padding: 0;
    }

    .col-contactform,
    .col-social {
      color: #333;
      background: #fff;
    }

    .col-contactform {
      input[type=checkbox]:focus,
      input[type=password]:focus,
      input[type=text]:focus,
      input[type=email]:focus,
      textarea:focus {
        box-shadow: 0 0 2px #e6332a;
      }
    }

    .list-networks {
      margin-top: 90px;
      float: left;

      li {
        width: 100%;
        float: left;
        text-align: left;

        &.first,
        &.last {
          text-align: left;
        }
      }

      a {
        color: #e6332a;
      }

    }

    .form-group--input {
      width: 45%;
      float: none;
      overflow: auto; /* Simulate float. */

      &.form-group--accept {
        width: 90%;
        padding-top: 0;
      }
    }

    .form-group--textarea {
      position: absolute;
      top: 0;
      left: 50%;
    }

    .form-control {
      border: 1px solid #e6332a;
      padding: 9px 7px;
    }

    .button.button-submit {
      width: 45%;
      color: #fff;
      background: #e6332a;

      &:hover {
        background: #841d18;
      }
    }

    .checkbox label::before {
      border: 1px solid #e6332a;
    }

    .checkbox a {
      color: #e6332a;

      &:hover {
        color: #841d18;
      }
    }

    .has-error .control-label {
      color: #e6332a;
    }

  }

  .site-footer {
    background: transparent;
  }

}

@media (min-width: 1200px) {

  .section-kontakt {

    .headline {
      margin-left: 26%;
    }

  }

  .site-footer {

    &:after {
      content: '';
      background: transparent url("/media/bg/kolibri-bg.png") center center no-repeat;
      position: absolute;
      bottom: 0;
      left: -50px;
      z-index: -1;
      width: 350px;
      height: 375px;
      transform: translateY(0px);
      opacity: 0;
      transition: all 1s;
    }

    @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      &:after {
        background-image: url("/media/bg/kolibri-bg@2x.png");
        background-size: 350px 375px;
      }
    }

  }

  .site-footer.js-inview {

    &:after {
      transform: translateY(-215px);
      opacity: 1;
    }

  }

}


@media (min-width: 1400px) {

  .section-lehrauftrag {

    .col-left,
    .col-right {
      .has-max-width {
        width: 80%;
        margin: 0 auto;
      }
    }

  }

  .site-footer {

    &:after {
      left: -20px;
    }

  }

}


@media (min-width: 1600px) {

  .site-footer {

    &:after {
      left: 0;
    }

  }

}
