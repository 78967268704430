.col {
  box-sizing: border-box;
  width: 100%;
  float: left;
  position: relative;
}

.col-right {
  float: right;
}

.col-mobile-1-2 {
  width: 50%;
}

.col-mobile-1-3 {
  width: 33.33%;
}

.col-mobile-1-5 {
  width: 20%;
}

@media (min-width: 667px) {

  .col-1-1 {
    width: 100%;
  }

  .col-1-2 {
    width: 50%;
  }

  .col-1-3 {
    width: 33.33%;
  }

  .col-1-4 {
    width: 25%;
  }

  .col-2-3 {
    width: 66.66%;
  }

  .col-1-6 {
    width: 16.66%;
  }

  .col-1-8 {
    width: 12.5%;
  }

  .col-tablet-1-1 {
    width: 100%;
  }

}

@media (min-width: 1024px) {

  .col-push-1-9 {
    margin-left: 11.11%;
  }

  .col-1-1 {
    width: 100%;
  }

  .col-1-2 {
    width: 50%;
  }

  .col-1-3 {
    width: 33.33%;
  }

  .col-1-4 {
    width: 25%;
  }

  .col-2-3 {
    width: 66.66%;
  }

  .col-1-6 {
    width: 20%; /* Sorry, custom grid with. */
  }

  .col-1-8 {
    width: 12.5%;
  }

}

@media (min-width: 1200px) {

  .col-push-1-9 {
    margin-left: 11.11%;
  }

  .col-1-6 {
    width: 16.66%;
  }

}