.timeline {
  white-space: nowrap;
  overflow-x: hidden;
}

.timeline ol {
  font-size: 0;
  width: 100vw;
  padding: 250px 0;
  transition: all 1s;
}

.timeline ol li {
  position: relative;
  display: inline-block;
  list-style-type: none;
  width: 160px;
  height: 2px;
  background: #e7e7e7;
}

.timeline ol li:first-child {
  background: #fff;
  width: 25px;
}

.timeline ol li:last-child {
  width: 280px;
}

.timeline ol li:not(:first-child) {
  margin-left: 14px;
}

.timeline ol li:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  left: calc(100% + 1px);
  bottom: 0;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #fff;
  border: 1px solid #e7e7e7;
  box-shadow: 0 0 5px #d1d1d1;
}

.timeline ol li div {
  position: absolute;
  left: calc(100% + 7px);
  width: 280px;
  padding: 15px;
  font-size: 1rem;
  white-space: normal;
  color: black;
  background: white;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    height: 35%;
    border-left: 1px solid #e7e7e7;
  }
}

.timeline ol li div::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ol li:nth-child(odd) div {
  top: -16px;
  transform: translateY(-100%);

  &:after {
    bottom: 0;
  }
}

.timeline ol li:nth-child(odd) div::before {
  top: 100%;
  border-width: 8px 8px 0 0;
  border-color: white transparent transparent transparent;
}

.timeline ol li:nth-child(even) div {
  @apply pt-8;

  top: calc(100% + 16px);

  &:after {
    top: 0;
  }
}

.timeline ol li:nth-child(even) div::before {
  top: -8px;
  border-width: 8px 0 0 8px;
  border-color: transparent transparent transparent white;
}

.timeline div {
  @apply font-light
}

.timeline time {
  @apply block text-xl font-bold mb-1;
}

.timeline strong {
  @apply block font-bold;
}

.timeline .arrows {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.timeline .arrows .arrow__prev {
  margin-right: 20px;
}

.timeline .disabled {
  opacity: 0.5;
}

.timeline .arrows img {
  width: 45px;
  height: 45px;
}

@media screen and (max-width: 767px) {
  .timeline ol,
  .timeline ol li {
    width: auto;
  }

  .timeline ol {
    padding: 0;
    transform: none !important;
  }

  .timeline ol li {
    display: block;
    height: auto;
    background: transparent;
  }

  .timeline ol li:first-child {
    margin-top: 25px;
    width: auto;
  }

  .timeline ol li:not(:first-child) {
    margin-left: auto;
  }

  .timeline ol li div {
    position: static;
    width: 94%;
    height: auto !important;
    margin: 0 auto 25px;
  }

  .timeline ol li:nth-child(odd) div {
    transform: none;
  }

  .timeline ol li:nth-child(odd) div::before,
  .timeline ol li:nth-child(even) div::before {
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    border: none;
    border-left: 1px solid white;
    height: 25px;
  }

  .timeline ol li:last-child,
  .timeline ol li:nth-last-child(2) div::before,
  .timeline ol li:not(:last-child)::after,
  .timeline .arrows {
    display: none;
  }

    .timeline ol li:nth-child(even) div {
        padding-top: 0;
    }


    .timeline ol li div {
        padding: 0;

        &:after {
            border-left: 1px solid #fff;
        }
    }
}
