/* Roboto 300 */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: fallback;
  font-weight: 300;
  src: url('../font/roboto-300.eot');
  src: local('Roboto Light'), local('Roboto-Light'),
       url('../font/roboto-300.eot?#iefix') format('embedded-opentype'),
       url('../font/roboto-300.woff2') format('woff2'),
       url('../font/roboto-300.woff') format('woff'),
       url('../font/roboto-300.ttf') format('truetype'),
       url('../font/roboto-300.svg#Roboto') format('svg');
}

/* Roboto 400 */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: fallback;
  font-weight: 400;
  src: url('../font/roboto-400.eot');
  src: local('Roboto'), local('Roboto-Regular'),
       url('../font/roboto-400.eot?#iefix') format('embedded-opentype'),
       url('../font/roboto-400.woff2') format('woff2'),
       url('../font/roboto-400.woff') format('woff'),
       url('../font/roboto-400.ttf') format('truetype'),
       url('../font/roboto-400.svg#Roboto') format('svg');
}

/* Karla 400 */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-display: fallback;
  font-weight: 400;
  src: url('../font/karla-400.eot');
  src: local('Karla'), local('Karla-Regular'),
       url('../font/karla-400.eot?#iefix') format('embedded-opentype'),
       url('../font/karla-400.woff2') format('woff2'),
       url('../font/karla-400.woff') format('woff'),
       url('../font/karla-400.ttf') format('truetype'),
       url('../font/karla-400.svg#Karla') format('svg');
}
