article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
  margin: 0;
}

*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

html {
  @apply font-sans;

  box-sizing: border-box;
  cursor: default;
  font-size: 16px;
  line-height: 1.4;
  color: #333;
  background: #fff;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  max-width: 100%;
  margin: 0 auto;
}

h1, .h1 {
  font-size: 56px;
  /* margin: .67em 0 0 0; */
  line-height: 1.2;
}

h2, .h2 {
  font-size: 42px;
  margin-bottom: 0;
  line-height: 1.2;
}

h3, .h3 {
  font-size: 18px;
  margin-bottom: 0;
  line-height: 1.2;
}

p, .p,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-size: 16px;
  margin-bottom: 0;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: bold;
  -webkit-font-smoothing: initial; /* Reset icon font smoothing. */
  -moz-osx-font-smoothing: initial; /* Reset icon font smoothing. */
}

p,
.p {
  font-weight: 600;
  -webkit-font-smoothing: initial; /* Reset icon font smoothing. */
  -moz-osx-font-smoothing: initial; /* Reset icon font smoothing. */
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

hr {
  height: 0;
  border-top: 1px solid #888;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  float: left;
  width: 100%;
}

dd {
  margin: 0;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

b, strong,
.b, .strong,
.bold {
  font-weight: bold;
}

small {
  font-size: 14px;
}

sub,
sup {
  font-size: 14px;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

::-moz-selection {
  background-color: #ff9999;
  color: #000000;
  text-shadow: none;
}

::selection {
  background-color: #ff9999;
  color: #000000;
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
  border-style: none;

  &.rounded {
    border-radius: 100%;
  }
}

svg {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

a {
  color: #e6332a;
  background-color: transparent;
  text-decoration: none;
  -webkit-text-decoration-skip: objects;

  &.responsive {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  &:hover {
    outline-width: 0;
    text-decoration: underline;
  }
}

.raw-link {
  /* Careful, this breaks the word wherever it is without a hyphen. */
  overflow-wrap: break-word;
  word-wrap: break-word;

  /* Adds a hyphen where the word breaks. */
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

a.nohover:hover,
a.email:hover,
a.tel:hover {
  text-decoration: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button,
input,
select,
textarea {
  @apply font-sans;
  background-color: transparent;
  border-style: none;
  color: inherit;
  font-size: 1em;
  margin: 0;
  border: 1px solid #fff;
}

.button {
  cursor: pointer;
}

button,
input {
  overflow: visible;
}

input[type=checkbox]:focus,
input[type=password]:focus,
input[type=text]:focus,
input[type=email]:focus,
textarea:focus {
  box-shadow: 0 0 2px #e6332a;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 0;
}

*:focus {
  outline: none;
}

textarea {
  overflow: auto;
  resize: vertical;
}

blockquote {
  margin: 0;
  padding: 30px 15px;

  p {
    font-size: 14px;
  }
}

[type="checkbox"],
[type="radio"] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  font-weight: 300;
}

[hidden][aria-hidden="false"] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden="false"]:focus {
  clip: auto;
}

/* Helpers */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}